import React, { useEffect } from "react";
import startWork from "../../../Js Files/Forms";
import { Link } from "react-router-dom";

function AddArea() {
  useEffect(() => {
    startWork();
  }, []);

  return (
    <div className="container-fluid p-5 justify-content-center d-flex align-items-center  ">
      <div className="row bg-white rounded py-3 shadow">
        <div>
          <h2 className="text-center text-primColor fw-bolder">Add Area</h2>
        </div>

        <form id="AddArea">
          <div className="form-group mt-2">
            <label htmlFor="title" className="fw-bold">
              Area Name
            </label>
            <input
              placeholder="Enter title"
              type="text"
              className="form-control"
              id="AreaName"
              name="AreaName"
            />

            <div className="alert d-none alert-danger" role="alert">
              you have to enter a value
            </div>
          </div>
          <div className="form-group mt-2" dir="rtl">
            <label htmlFor="title" className="fw-bold">
              إسم الحى
            </label>
            <input
              placeholder="أدخل العنوان"
              type="text"
              className="form-control"
              id="title"
              name="arAreaName"
            />

            <div className="alert d-none alert-danger" role="alert">
              لقد أدخلت قيمة غير صحيحة
            </div>
          </div>
          <div className="form-group mt-2 mb-4">
            <label htmlFor="governorates" className="fw-bold">
              Govenorate
            </label>

            <select
              id="governorates"
              name="govenorate"
              className="form-control"
            >
              <option value="" disabled selected>
                Choose governorates{" "}
              </option>
              <option value="1">Alexandria</option>
              <option value="2">Port Said</option>
              <option value="3">Suez</option>
              <option value="4">Damietta</option>
              <option value="5">Dakahlia</option>
              <option value="6">Cairo</option>
              <option value="7">Sharkia</option>
              <option value="8">Qalyubia</option>
              <option value="9">Kafr El Sheikh</option>
              <option value="10">Gharbia</option>
              <option value="11">Giza</option>
              <option value="12">Monufia</option>
              <option value="13">Beheira</option>
              <option value="14">Ismailia</option>
              <option value="15">Beni Suef</option>
              <option value="16">Fayoum</option>
              <option value="17">Minya</option>
              <option value="18">Asyut</option>
              <option value="19">Sohag</option>
              <option value="20">Qena</option>
              <option value="21">Aswan</option>
              <option value="22">Luxor</option>
              <option value="23">Red Sea</option>
              <option value="24">New Valley</option>
              <option value="25">Matrouh</option>
              <option value="26">North Sinai</option>
              <option value="27">South Sinai</option>
            </select>

            <div className="alert d-none alert-danger" role="alert">
              you have to enter a value{" "}
            </div>
          </div>

          <div className="d-flex justify-content-center flex-column my-3 flex-wrap">
            <button
              type="submit"
              className="btn btn-success bg-primColor fw-bolder fs-5 submitButton"
            >
              Add Area
            </button>

            <div
              className="alert AlertMessage d-none text-center mt-2 alert-danger "
              role="alert"
            ></div>

            <div
              className="alert SuccessMessage d-none text-center mt-2 alert-success "
              role="alert"
            ></div>
          </div>

          <Link to="/Area" className="btn btn-outline-success w-100 ">
            return to Area
          </Link>
        </form>
      </div>
    </div>
  );
}

export default AddArea;
