import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AparmentSlider from "../ApartmentSliderComponent/AparmentSlider";
import showStyle from "./ShowApart.module.css";
function ShowApart(props) {
  const location = useLocation();
  const apartment = location.state.apartment;
  const arApartment = location.state.arApartment;
  const [arabicFlag, setArabicFlag] = useState(false);

  return (
    <div className={"container-fluid  myVh-100 " + showStyle.py_izi_5}>
      <div className="row   shadow rounded-5 m-auto bg-white overflow-hidden position-relative">
        <div className="col-md-6 p-0">
          <AparmentSlider
            apartmentId={apartment.id}
            DeleteImageFeature={false}
            DetailsFlagPage={true}
            flatImages={[...apartment.pictures]}
          />
        </div>

        <div className="col-md-6  ">
          <div className="p-4">
            <h2 className="text-center text-primColor fw-bolder">
              {arabicFlag?arApartment.title:apartment.title}
            </h2>
          </div>

          <div className="p-4 fs-4">
            <div className="form-group mt-2 d-flex ">
              <p className="fw-bold me-3">Title:</p>
              <p>{arabicFlag?arApartment.title:apartment.title}</p>
            </div>
            <div className="form-group mt-2 d-flex ">
              <p className="fw-bold me-3">Floor:</p>
              <p>{arabicFlag? arApartment.floor :apartment.floor}</p>
            </div>
            <div className="form-group mt-2 d-flex ">
              <p className="fw-bold me-3">Price:</p>
              <p>{apartment.price} LE</p>
            </div>
            <div className="form-group mt-2 d-flex ">
              <p className="fw-bold me-3">payPrice:</p>
              <p>{apartment.payPrice} LE</p>
            </div>
            <div className="form-group mt-2 d-flex ">
              <p className="fw-bold me-3">Apartment Condition:</p>
              <p>{arabicFlag?arApartment.condition : apartment.condition}</p>
            </div>
            <div className="form-group mt-2 d-flex ">
              <p className="fw-bold me-3">Area:</p>
              <p>{arabicFlag?arApartment.area : apartment.area}</p>
            </div>
            <div className="form-group mt-2 d-flex ">
              <p className="fw-bold me-3">Region</p>
              <p>{arabicFlag?arApartment.region : apartment.region}</p>
            </div>
            <div className="form-group mt-2 d-flex ">
              <p className="fw-bold me-3">Description:</p>
              <p>{arabicFlag?arApartment.description : apartment.description}</p>
            </div>
          </div>
        </div>

        <div
          className="form-check form-switch position-absolute top-0 end-0 m-3"
          style={{ width: "fit-content" }}
        >
          <input
            className="form-check-input px-3 py-2"
            type="checkbox"
            id="arabicCheck"
            onChange={(e) => {
              setArabicFlag(e.target.checked);
            }}
          />
          <label className="form-check-label" for="arabicCheck">
            Arabic
          </label>
        </div>
      </div>

      <div className="row">
        <Link to="/Apartment" className="btn btn-success mt-2 mx-auto w-25 ">
          return to Apartment
        </Link>
      </div>
    </div>
  );
}

export default ShowApart;
