const BaseURl = "https://api.summitegypt.com";
const AdminBaseURl = `${BaseURl}/accounts/admins`;
const AreaBaseURl = `${BaseURl}/Areas`;
const RegionBaseURl = `${BaseURl}/Regions`;
const ApartmentBaseURl = `${BaseURl}/Apartments`;
export default class ApiCalling {
  ////////////////// General ///////////////////////////

  /**
   * check if the user is exist or not
   * @param {string} token
   * @returns boolean
   */
  static async checkUser(token) {
    let resultReturned;
    try {
      await fetch(`${BaseURl}/accounts/validateToken?token=${token}`)
        .then((response) => response.json())
        .then((result) => (resultReturned = result));
      if (resultReturned.code == undefined) {
        return {
          status: true,
          token: resultReturned.token,
        };
      } else {
        return {
          status: false,
        };
      }
    } catch (error) {
      return {
        status: false,
      };
    }
  }

  /**
   * this function get user info
   * @param {string} token
   * @returns {object}
   */
  static async getUserInfo(token) {
    const response = await fetch(`${BaseURl}/accounts/currentuser`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

    return response;
  }
  /////////////////////////// Admin ///////////////////////////
  /** 
     * this function help you to login admin
     * @param {object} user
     * take user object like this {
        "EmailOrPhone": "abdumezar1@gmail.com", // Email Or Phone Number Or UserName
        "Password": "01140267118",
        "RemeberMe" : 0
        }
     * @returns {object}
     */
  static async loginAdmin(user) {
    const response = await fetch(`${AdminBaseURl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }).then((response) => response.json());

    return response;
  }
  /**
     * this function help change admin password
     * @param {object} user
     * take user object like this {
        "current_password" : "User123",
        "new_password" : "user123"
        }
        * @param {string} token
        * @returns {object}
        */
  static async changeAdminPassword(user, token) {
    const response = await fetch(`${AdminBaseURl}/changepassword`, {
      method: "Put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    }).then((response) => response.json());
    return response.data;
  }
  /**
   * this function will let admin to log out
   * @param {string} token
   * @returns {object}
   */
  static async logoutAdmin(token) {
    const response = await fetch(`${AdminBaseURl}/logout`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());
    return response;
  }

  /**
     * this function Edit admin Profile
     * @param {object} user
     * take user object like this{
        "Name" : "أحمد محمد",
        "PhoneNumber" : "01129283294",
        "Gender" : "M",
        "Address" : "Cairo"
    }
    * @param {string} token
    * @returns {object}
    */
  static async editAdminProfile(user, token) {
    const response = await fetch(`${AdminBaseURl}/updatecurrent`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    }).then((response) => response.json());

    return response;
  }

  /////////////////////////// Areas  ///////////////////////////
  /**
     * this function to add new area
     * @param {object} area
     * take area object like this
     *   {
             "Name": "6th October",
             "GovernorateId": 2
        }
     * @returns {object}
     */
  static async AddArea(area, token) {
    const response = await fetch(`${AreaBaseURl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(area),
    }).then((response) => response.json());

    return response;
  }

  /**
     * this function to edit area
     * @param {object} area
     * take area object like this
     *   {
            "Name": "6th October - Updated",
            "GovernorateId": 3
         }
     * @returns {object}
     */
  static async EditArea(areaId, area, token) {
    const response = await fetch(`${AreaBaseURl}/${areaId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(area),
    }).then((response) => response.json());

    return response;
  }
  /**
   * this function to get areas by GovId
   * @param {int} GovId
   * @returns {object}
   */
  static async getAreasOfGov(GovId, token) {
    const response = await fetch(`${AreaBaseURl}/${GovId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

    return response;
  }
  /**
   * this function to get all Areas
   * @returns {object}
   */
  static async getAllAreas(param = "", token) {
    const response = await fetch(`${AreaBaseURl}${param}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

    return response;
  }
  /**
   * this function to delete area
   * @param {int} areaId
   * @returns boolean
   */
  static async DeleteArea(areaId, token) {
    const response = await fetch(`${AreaBaseURl}/${areaId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

    return response;
  }
  /////////////////////////// Regions ///////////////////////////
  /**
    * this function to add new region
    * @param {object} region
    * take region object like this
    *  {
           "Name": "1st District",
           "AreaId": 3
       }
    * @returns {object}
    */
  static async AddRegion(region, token) {
    const response = await fetch(`${RegionBaseURl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(region),
    }).then((response) => response.json());

    return response;
  }
  /**
* this function to edit region
* @param {object} region
* take region object like this
*  {
       "Name": "1st District-updated",
       "AreaId": 3
   }
* @returns {object}
*/
  static async EditRegion(regionId, region, token) {
    const response = await fetch(`${RegionBaseURl}/${regionId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(region),
    }).then((response) => response.json());

    return response;
  }
  /**
   * this function to get regions by AreaId
   * @param {int} AreaId
   * @returns {object}
   */
  static async getRegionsOfArea(AreaId, token) {
    const response = await fetch(`${RegionBaseURl}/${AreaId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

    return response;
  }

  /**
   * this function to get all Regions
   * @returns {object}
   */
  static async getAllRegions(param = "", token) {
    const response = await fetch(`${RegionBaseURl}${param}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

    return response;
  }
  /**
   * this function to delete region
   * @param {int} regionId
   * @returns boolean
   */
  static async DeleteRegion(regionId, token) {
    const response = await fetch(`${RegionBaseURl}/${regionId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

    return response;
  }
  /////////////////////////// Aparetments ///////////////////////////
  /**
   * this function to add new aparetment
   * @param {string} Title
   * @param {string} Description
   * @param {number} RegionId
   * @param {string} Floor
   * @param {string} Condition // 1 for Rent | 2 for Buy | 3 for Both
   * @param {number} Price
   * @param {Array<File>} files  // An array of File objects representing images.
   * @param {number} ApartmentType
   * @param {string} token
   * @returns boolean
   */
  static async addApartment(
    Title,
    Description,
    RegionId,
    Floor,
    Condition,
    Price,
    files,
    ApartmentType,
    arTitle,
    arDescription,
    arFloor,
    arCondition,
    payPrice,
    token
  ) {
    const formData = new FormData();
    formData.append("Title", Title);
    formData.append("arTitle", arTitle);

    formData.append("Description", Description);
    formData.append("arDescription", arDescription);

    formData.append("Floor", Floor);
    formData.append("arFloor", arFloor);

    formData.append("RegionId", RegionId);
    formData.append("Condition", Condition);
    formData.append("arCondition", arCondition);

    formData.append("Price", Price);
    formData.append("payPrice", payPrice);

    formData.append("ApartmentType", Number(ApartmentType));

    for (let i = 0; i < files?.length; i++) {
      formData.append(`Pictures[${i}].file`, files[i].file);
      formData.append(`Pictures[${i}].order`, files[i].order);
    }

    const response = await fetch(`${ApartmentBaseURl}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }).then((response) => response.json());
    return response;
  }
  /**
   * this function to update aparetment
   * @param {number} AppartmentId  // 1 based
   * @param {string} Title
   * @param {string} Description
   * @param {number} RegionId
   * @param {string} Floor
   * @param {string} Condition // 1 for Rent | 2 for Buy | 3 for Both
   * @param {number} Price
   * @param {Array<File>} files  // An array of File objects representing images.
   * @param {number} ApartmentType
   * @param {string} token
   * @returns boolean
   */
  static async editApartment(
    ApartmentId,
    Title,
    Description,
    RegionId,
    Floor,
    Condition,
    Price,
    payPrice,
    files,
    ApartmentType,
    arTitle,
    arDescription,
    arFloor,
    arCondition,
    token
  ) {
    const formData = new FormData();
    formData.append("Title", Title);
    formData.append("Description", Description);
    formData.append("Floor", Floor);
    formData.append("RegionId", RegionId);
    formData.append("Condition", Condition);
    formData.append("Price", Price);
    formData.append("payPrice", payPrice);
    formData.append("ApartmentType", ApartmentType);
    // Arabic
    formData.append("arTitle", arTitle);
    formData.append("arDescription", arDescription);
    formData.append("arFloor", arFloor);
    formData.append("arCondition", arCondition);
    const response = await fetch(`${ApartmentBaseURl}/${ApartmentId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }).then((response) => response.json());

    return response;
  }
  /**
   * this function to add Images
   * @returns {object}
   */
  static async addImages(files, ApartmentId, token) {
    const formDataImages = new FormData();

    for (let i = 0; i < files?.length; i++) {
      formDataImages.append(`Pictures[${i}].file`, files[i].file);
      formDataImages.append(`Pictures[${i}].order`, files[i].order);
    }
    const responseImage = await fetch(
      `${ApartmentBaseURl}/AddImages/${ApartmentId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataImages,
      }
    ).then((responseImage) => responseImage.json());

    return responseImage;
  }
  /**
   * this function to update Image Order
   * @returns {object}
   * @param {object} images
   */
  static async updateImageOrder(images, ApartmentId, token) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    console.log(images);
    const raw = JSON.stringify(images);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
    };

    let responseImage = await fetch(
      ApartmentBaseURl + "/UpdateImageOrder/" + ApartmentId,
      requestOptions
    ).then((responseImage) => responseImage.json());
 
    return responseImage;
  }
  /**
   * this function to get all apartments
   * @returns {object}
   */
  static async getAllApartments(param = "") {
    const response = await fetch(`${ApartmentBaseURl}${param}`, {
      method: "GET",
    }).then((response) => response.json());

    return response;
  }

  /**
   * this function to delete apartment
   * @param {int} appartmentId
   * @returns boolean
   */
  static async deleteAppartment(appartmentId, token) {
    const response = await fetch(`${ApartmentBaseURl}/${appartmentId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

    return response;
  }

  /**
   * this function to delete Image
   * @param {string} ImageId
   * @returns boolean
   */
  static async deleteImage(ImageId, token) {
    const response = await fetch(`${ApartmentBaseURl}/DeleteImage/${ImageId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

    return response;
  }
}
