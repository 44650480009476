import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import startWork from "../../../Js Files/Forms";
import ErrorPage from "../../ErrorPage/ErrorPage";

function EditArea() {
  const location = useLocation();
  const [area, setArea] = useState({});

  useEffect(() => {
    startWork();
    setArea(location.state.area);
  }, []);
  useEffect(() => {
    document.querySelector("#governorates").value = area.governorate;
  }, [area]);

  if (area === null) {
    return <ErrorPage />;
  }
  return (
    <div className="container-fluid p-5 justify-content-center d-flex align-items-center  ">
      <div className="row bg-white rounded py-3 shadow">
        <div>
          <h2 className="text-center text-primColor fw-bolder">Edit Area</h2>
        </div>

        <form id="EditArea">
          <div className="form-group mt-2">
            <label htmlFor="title" className="fw-bold">
              Area Name
            </label>
            <input
              placeholder="Enter title"
              type="text"
              className="form-control"
              id="title"
              name="AreaName"
              onChange={(e) => setArea({ ...area, name: e.target.value })}
              value={area.name}
            />

            <div className="alert d-none alert-danger" role="alert">
              you have to enter a value{" "}
            </div>
          </div>
          <div className="form-group mt-2" dir="rtl">
            <label htmlFor="title" className="fw-bold">
              إسم الحى
            </label>
            <input
              placeholder="أدخل العنوان"
              type="text"
              className="form-control"
              id="title"
              name="arAreaName"
              value={area.arName}
              onChange={(e) => setArea({ ...area, arName: e.target.value })}
            />

            <div className="alert d-none alert-danger" role="alert">
              لقد أدخلت قيمة غير صحيحة
            </div>
          </div>
          <div className="form-group mt-2 mb-4">
            <label htmlFor="governorates" className="fw-bold">
              Govenorate
            </label>

            <select
              id="governorates"
              name="govenorate"
              className="form-control"
            >
              <option value="" disabled selected>
                {" "}
                all Governorates{" "}
              </option>
              <option value="1">Alexandria</option>
              <option value="2">Port Said</option>
              <option value="3">Suez</option>
              <option value="4">Damietta</option>
              <option value="5">Dakahlia</option>
              <option value="6">Cairo</option>
              <option value="7">Sharkia</option>
              <option value="8">Qalyubia</option>
              <option value="9">Kafr El Sheikh</option>
              <option value="10">Gharbia</option>
              <option value="11">Giza</option>
              <option value="12">Monufia</option>
              <option value="13">Beheira</option>
              <option value="14">Ismailia</option>
              <option value="15">Beni Suef</option>
              <option value="16">Fayoum</option>
              <option value="17">Minya</option>
              <option value="18">Asyut</option>
              <option value="19">Sohag</option>
              <option value="20">Qena</option>
              <option value="21">Aswan</option>
              <option value="22">Luxor</option>
              <option value="23">Red Sea</option>
              <option value="24">New Valley</option>
              <option value="25">Matrouh</option>
              <option value="26">North Sinai</option>
              <option value="27">South Sinai</option>
            </select>

            <div className="alert d-none alert-danger" role="alert">
              you have to enter a value{" "}
            </div>
          </div>

          <div className="d-flex justify-content-center my-3 flex-column flex-wrap">
            <button
              type="submit"
              data_id_ar={location.state.area.id}
              className="btn btn-success bg-primColor fw-bolder fs-5 submitButton"
            >
              Save Changes
            </button>

            <div
              className="alert AlertMessage d-none text-center mt-2 alert-danger  m-auto"
              role="alert"
            ></div>

            <div
              className="alert SuccessMessage d-none text-center mt-2 alert-success  m-auto"
              role="alert"
            ></div>
          </div>

          <Link to="/Area" className="btn btn-outline-success w-100 ">
            return to Area
          </Link>
        </form>
      </div>
    </div>
  );
}

export default EditArea;
