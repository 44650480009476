import React, { useEffect } from "react";
import ImageReorderModule from "./ImageReorderModule";
import ApiCalling from "../../../Js Files/apiCalling";
import swal from "sweetalert";
export default function ImageModuleContainer({
  images,
  setIsEditImages,
  setImages,
  apartmentId,
}) {
  const handleSave = (images) => {
    swal({
      title: "Are you sure you want to reorder images ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willEdit) => {
      if (willEdit) {
        let updatedImages = [...images];
        updatedImages.forEach((item, index) => {
          item.order = index + 1;
        });
        setImages([...updatedImages]);
        let response = await ApiCalling.updateImageOrder(
          [...updatedImages],
          apartmentId,
          localStorage.getItem("token")
        );
        if (response.code == 200)
          swal("Poof! Your Image has been updated!", {
            icon: "success",
          });

        else if (response.code == 401) {
          swal("Your session has been expired!", {
            icon: "error",
          });
          localStorage.clear();
          window.location.reload();
        }
        
      } else {
        swal("Your Image hasn't been updated!");
      }
    });
    setIsEditImages(false);

  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains("position-fixed")) {
        setIsEditImages(false);
      }
    };
    window.addEventListener("click", handleClickOutside);

    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setIsEditImages(false);
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  //   editImageOrder = (e, index) => {
  //
  //   };
  return (
    <div
      className=" position-fixed top-0 end-0 start-0 bottom-0 d-flex justify-content-center align-items-center flex-wrap"
      style={{ zIndex: "999999999", backgroundColor: " rgba(0,0,0,0.5)" }}
    >
      <div className="bg-white text-center rounded-5 card">
        <h3 className="fs-1 text-success">Image Reorder Module</h3>
        <ImageReorderModule images={images} onSave={handleSave} />
      </div>
    </div>
  );
}
