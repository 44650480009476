import ApiCalling from "./apiCalling";
export default class CheckResponse {
  /* Addition Area Region Apartment */
  /** this function to check the response of adding new Area to the system
   *@param {HTMLElement} SubmitButton
   *@param {Object} user
   *@return {void}
   */
  /**
     *response when True return this
     *  {
    "id": 6,
    "name": "Qasr El Nil",
    "governorate": 6,
    "regions": []
    }
     */
  static async AddArea(user, SubmitButton) {
    let AlertMessage = document.querySelector(".AlertMessage");
    let token = localStorage.getItem("token");
    let SuccessMessage = document.querySelector(".SuccessMessage");
    let reqUser = {
      Name: user.AreaName,
      arName: user.arAreaName,
      GovernorateId: user.govenorate,
    };

    let response = await ApiCalling.AddArea(reqUser, token);

    if (response.id) {
      SubmitButton.innerHTML = `Add Area`;
      SubmitButton.disabled = false;
      AlertMessage.classList.add("d-none");
      SuccessMessage.classList.remove("d-none");
      SuccessMessage.innerHTML = `Area Added Successfully`;
    } else {
      SubmitButton.innerHTML = `Add Area`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      AlertMessage.innerHTML = `Area Not Added Successfully`;
    }
  }
  /**
   * this function to check the response of adding new Region to the system
   * @param {object} user
   * @param {HTMLElement} SubmitButton
   * @returns {void}
   *
   */
  /**
     * response when True return this
     * {
    "id": 4,
    "name": "Al Ashgar District",
    "areaId": 1
}   */

  static async AddRegion(user, SubmitButton) {
    let AlertMessage = document.querySelector(".AlertMessage");
    let token = localStorage.getItem("token");
    let SuccessMessage = document.querySelector(".SuccessMessage");
    let reqUser = {
      Name: user.RegionName,
      AreaId: user.Area,
      arName: user.arRegionName,
    };

    let response = await ApiCalling.AddRegion(reqUser, token);

    if (response.id) {
      SubmitButton.innerHTML = `Add Region`;
      SubmitButton.disabled = false;
      AlertMessage.classList.add("d-none");
      SuccessMessage.classList.remove("d-none");
      SuccessMessage.innerHTML = `Region Added Successfully`;
    } else {
      SubmitButton.innerHTML = `Add Region`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      AlertMessage.innerHTML = `Region Not Added Successfully`;
    }
  }
  /**
   * this function to check the response of adding new Apartment to the system
   * @param {object} user
   * @param {HTMLElement} SubmitButton
   * @returns {void}
   *
   */
  /**
}   */

  static async AddApartment(user, SubmitButton) {
    let AlertMessage = document.querySelector(".AlertMessage");
    let token = localStorage.getItem("token");
    let SuccessMessage = document.querySelector(".SuccessMessage");
    let reqUser = user;
    //Title, Description, RegionId, Floor, Condition, Price, files, ApartmentType
    let response = await ApiCalling.addApartment(
      reqUser.title,
      reqUser.Description,
      reqUser.Region,
      reqUser.floor,
      reqUser.apartmentCondition,
      reqUser.price,
      reqUser.Images != undefined ? reqUser.Images : null,
      reqUser.AprtmentType,
      reqUser.arTitle,
      reqUser.arDescription,
      reqUser.arFloor,
      reqUser.arApartmentCondition,
      reqUser.payPrice,
      token
    );

    if (response.code == 200) {
      SubmitButton.innerHTML = `Add Apartment`;
      SubmitButton.disabled = false;
      AlertMessage.classList.add("d-none");
      SuccessMessage.classList.remove("d-none");
      SuccessMessage.innerHTML = response.message;
    } else if (response.code == 400) {
      SubmitButton.innerHTML = `Add Apartment`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      SuccessMessage.innerHTML = response.message;
    } else if (response.code == 401) {
      // navigate login
      window.location.href = "/login";
    } else {
      SubmitButton.innerHTML = `Add Apartment`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      AlertMessage.innerHTML = `Apartment Not Added Successfully`;
    }
  }

  /* Edition Area Region Apartment  */
  /** this function to check the response of Edit Area in the system
   *@param {HTMLElement} SubmitButton
   *@param {Object} user
   *@return {void}
   */
  /**
     * response when True return this
     *  {
        "id": 6,
        "name": "Qasr El Nil",
        "governorate": 6,
        "regions": []
        }
         */
  static async EditArea(user, SubmitButton) {
    let AlertMessage = document.querySelector(".AlertMessage");
    let token = localStorage.getItem("token");
    let SuccessMessage = document.querySelector(".SuccessMessage");
    let reqUser = {
      Name: user.AreaName,
      arName: user.arAreaName,
      GovernorateId: user.govenorate,
    };

    let response = await ApiCalling.EditArea(
      SubmitButton.getAttribute("data_id_ar"),
      reqUser,
      token
    );

    if (response.code == 404) {
      SubmitButton.innerHTML = `Save Change`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      AlertMessage.innerHTML = `Area Not Edited Successfully`;
    } else if (response.code == 400) {
      SubmitButton.innerHTML = `Save Change`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      AlertMessage.innerHTML = response.errors[0];
    } else {
      SubmitButton.innerHTML = `Save Change`;
      SubmitButton.disabled = false;
      AlertMessage.classList.add("d-none");
      SuccessMessage.classList.remove("d-none");
      SuccessMessage.innerHTML = `Area Edited Successfully`;
    }
  }
  /**
   * this function to check the response of Edit Region in the system
   * @param {object} user
   * @param {HTMLElement} SubmitButton
   * @returns {void}
   *
   */
  /**
     * response when True return this
     * {
        "id": 4,
        "name": "Al Ashgar District",
        "areaId": 1
        }   */
  static async EditRegion(user, SubmitButton) {
    let AlertMessage = document.querySelector(".AlertMessage");
    let token = localStorage.getItem("token");
    let SuccessMessage = document.querySelector(".SuccessMessage");
    let reqUser = {
      Name: user.Region,
      AreaId: user.Area,
      arName: user.arRegionName,
    };
    let response = await ApiCalling.EditRegion(
      SubmitButton.getAttribute("data_id_re"),
      reqUser,
      token
    );

    if (response.code == 404) {
      SubmitButton.innerHTML = `Save Change`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      AlertMessage.innerHTML = `Region Not Edited Successfully`;
    } else if (response.code == 400) {
      SubmitButton.innerHTML = `Save Change`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      AlertMessage.innerHTML = response.errors[0];
    } else {
      SubmitButton.innerHTML = `Save Change`;
      SubmitButton.disabled = false;
      AlertMessage.classList.add("d-none");
      SuccessMessage.classList.remove("d-none");
      SuccessMessage.innerHTML = `Region Edited Successfully`;
    }
  }
  /**
   * this function to check the response of adding new Apartment to the system
   * @param {object} user
   * @param {HTMLElement} SubmitButton
   * @returns {void}
   *
   */
  /**
}   */

  static async EditApartment(user, SubmitButton) {
    let AlertMessage = document.querySelector(".AlertMessage");
    let token = localStorage.getItem("token");
    let SuccessMessage = document.querySelector(".SuccessMessage");
    let reqUser = user;
    //Title, Description, RegionId, Floor, Condition, Price, files, ApartmentType
    let response = await ApiCalling.editApartment(
      SubmitButton.getAttribute("data_id_apart"),
      reqUser.title,
      reqUser.Description,
      reqUser.Region,
      reqUser.floor,
      reqUser.apartmentCondition,
      reqUser.price,
      reqUser.payPrice,
      reqUser.Images != undefined ? reqUser.Images : null,
      reqUser.AprtmentType,
      reqUser.arTitle,
      reqUser.arDescription,
      reqUser.arFloor,
      reqUser.arApartmentCondition,
      token
    );

    if (response.code == 200) {
      SubmitButton.innerHTML = `Edit Apartment`;
      SubmitButton.disabled = false;
      AlertMessage.classList.add("d-none");
      SuccessMessage.classList.remove("d-none");
      SuccessMessage.innerHTML = response.message;
    } else if (response.code == 400) {
      SubmitButton.innerHTML = `Edit Apartment`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      SuccessMessage.innerHTML = response.message;
    } else if (response.code == 401) {
      // navigate login
      window.location.href = "/login";
    } else {
      SubmitButton.innerHTML = `Edit Apartment`;
      SubmitButton.disabled = false;
      SuccessMessage.classList.add("d-none");
      AlertMessage.classList.remove("d-none");
      AlertMessage.innerHTML = `Apartment Not Added Successfully`;
    }
  }
}
