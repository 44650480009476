import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./Components/RootLayout/RootLayout";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import Login from "./Components/LoginComponent/Login";
import Apartment from "./Components/ApartmentComponent/Apartment";
import Area from "./Components/AreaComponent/Area";
import Region from "./Components/RegionComponent/Region";
import AddApart from "./Components/ApartmentComponent/AddApartComponent/AddApart";
import ShowApart from "./Components/ApartmentComponent/ShowApartComponent/ShowApart";
import EditApart from "./Components/ApartmentComponent/EditApartComponent/EditApart";
import AddArea from "./Components/AreaComponent/AddAreaComponent/AddArea";
import EditArea from "./Components/AreaComponent/EditAreaComponent/EditArea";
import AddRegion from "./Components/RegionComponent/AddRegionComponent/AddRegion";
import EditRegion from "./Components/RegionComponent/EditRegionComponent/EditRegion";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import InverseProtectedRoute from "./Components/InverseProtectedRoute/InverseProtectedRoute";

// This component won't render anything visible because it immediately navigates.

function App() {
  let routers;
  routers = createBrowserRouter([
    {
      path: "/"
      ,
      element: (
        <ProtectedRoute>
          <RootLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute>
              <Apartment />
            </ProtectedRoute>
          ),
        },
        {
          path: "/Apartment",
          element: (
            <ProtectedRoute>
              <Apartment />
            </ProtectedRoute>
          ),
        },
        {
          path: "/Apartment/AddApart",
          element: (
            <ProtectedRoute>
              <AddApart />
            </ProtectedRoute>
          ),
        },
        {
          path: "/Apartment/ShowApart",
          element: (
            <ProtectedRoute>
              <ShowApart />
            </ProtectedRoute>
          ),
        },
        {
          path: "/Apartment/EditApart",
          element: (
            <ProtectedRoute>
              <EditApart />
            </ProtectedRoute>
          ),
        },

        {
          path: "/Area",
          element: (
            <ProtectedRoute>
              <Area />
            </ProtectedRoute>
          ),
        },

        {
          path: "/Area/AddArea",
          element: (
            <ProtectedRoute>
              <AddArea />
            </ProtectedRoute>
          ),
        },
        {
          path: "/Area/EditArea",
          element: (
            <ProtectedRoute>
              <EditArea />
            </ProtectedRoute>
          ),
        },
        {
          path: "/Region",
          element: (
            <ProtectedRoute>
              <Region />
            </ProtectedRoute>
          ),
        },
        {
          path: "/Region/AddRegion",
          element: (
            <ProtectedRoute>
              <AddRegion />
            </ProtectedRoute>
          ),
        },
        {
          path: "/Region/EditRegion",
          element: (
            <ProtectedRoute>
              <EditRegion />
            </ProtectedRoute>
          ),
        },
      ],
      errorElement: <ErrorPage />,
    },
    /**
     * 
     * {
      path: "/Apartment", element: <ProtectedRoute><RootLayout /></ProtectedRoute>, children: [
        {
           index:true, element: <ProtectedRoute><Apartment /></ProtectedRoute>
        },
        { path: '/Apartment/AddApart', element: <ProtectedRoute><AddApart /></ProtectedRoute> },
        { path: '/Apartment/ShowApart', element: <ProtectedRoute><ShowApart /></ProtectedRoute> },
        { path: '/Apartment/EditApart', element: <ProtectedRoute><EditApart /></ProtectedRoute> },

        {
          path: 'Area', element: <ProtectedRoute><Area /></ProtectedRoute>
        },
        
        { path: 'Area/AddArea', element: <ProtectedRoute><AddArea /></ProtectedRoute> },
        { path: 'Area/EditArea', element: <ProtectedRoute><EditArea /></ProtectedRoute> },
        {
          path: 'Region', element: <ProtectedRoute><Region /></ProtectedRoute>      
         },
         { path: 'Region/AddRegion', element: <ProtectedRoute><AddRegion /></ProtectedRoute> },
         { path: 'Region/EditRegion', element: <ProtectedRoute><EditRegion /></ProtectedRoute> },



      ]
    },
    */

    {
      path: "login",
      element: (
        <InverseProtectedRoute>
          {" "}
          <Login />{" "}
        </InverseProtectedRoute>
      ),
    },
    { path: "*", element: <ErrorPage /> },
  ]);

  return (
    <React.Fragment>
      <RouterProvider router={routers} />
    </React.Fragment>
  );
}

export default App;
