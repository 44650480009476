// ImageUpload.js
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import "./ImageUpload.css";

const ImageUpload = ({ formik }) => {
  const [images, setImages] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const onDrop = (acceptedFiles) => {
    const maxSize = 5 * 1024 * 1024;

    const orderedFiles = acceptedFiles.map((file, index) => ({
      file,
      order: images.length + index + 1,
    }));

    const isSizeValid = orderedFiles.every((imgObj) => imgObj.file.size <= maxSize);

    if (!isSizeValid) {
      formik.setFieldError("images", "One or more images exceed the 5MB size limit");
    } else {
      formik.setFieldError("images", ""); // Clear error message
      const updatedImages = [...images, ...orderedFiles];
      setImages(updatedImages);
      formik.setFieldValue("images", updatedImages); // Update Formik field
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  const handleDeleteImage = (indexToDelete) => {
    const updatedImages = images.filter((_, index) => index !== indexToDelete);
    setImages(updatedImages);
    formik.setFieldValue("images", updatedImages); // Update Formik field
  };

  const renderPreviewImages = () => {
    return images.map((imgObj, index) => (
      <div key={index} className="image-preview">
        <img
          src={URL.createObjectURL(imgObj.file)}
          alt={`Image ${imgObj.order}`}
        />
        <button
          type="button"
          className="delete-button"
          onClick={() => handleDeleteImage(index)}
        >
          &times;
        </button>
        <p>Order: {imgObj.order}</p>
      </div>
    ));
  };

  return (
    <div className="d-flex justify-content-center">
      <button
        type="button"
        onClick={() => setPopupOpen(true)}
        className="btn btn-primary w-75"
      >
        Upload Images
      </button>

      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button
              type="button"
              onClick={() => setPopupOpen(false)}
              className="popup-close-button"
            >
              &times;
            </button>
            <div className="image-upload-section">
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              <div className="image-preview-container">
                {renderPreviewImages()}
              </div>
              <button
                type="button"
                onClick={() => {
                  setPopupOpen(false);
                }}
                className="btn btn-success mt-3"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
