import React, { useState, useEffect } from "react";
import regionStyle from "./Region.module.css";
import { Link, useNavigate } from "react-router-dom";
import ApiCalling from "../../Js Files/apiCalling";
import swal from "sweetalert";

const Region = () => {
  const navigate = useNavigate();
  const [response, setResponse] = useState({
    regionId: "",
    name: "",
    pageIndex: 0,
    areaid: "",
    govId: "",
    data: [],
  });
  const [arabicFlag, setArabicFlag] = useState(false);
  const [loading, setLoading] = useState(true);

  const getAllData = async (
    pageIndex = response.pageIndex + 1,
    govId = response.govId,
    areaid = response.areaid,
    regionId = response.regionId,
    searchFlag = false
  ) => {
    try {
      const apiResponse = await ApiCalling.getAllRegions(
        `?regionId=${regionId}&name=${response.name}&pageIndex=${pageIndex}&areaid=${areaid}&govId=${govId}`,
        localStorage.getItem("token")
      );
      if (apiResponse.data) {
        if (apiResponse.data.length === 0) {
          setResponse((prevState) => ({
            ...prevState,
            data: searchFlag
              ? [...apiResponse.data]
              : [...response.data, ...apiResponse.data],
            pageIndex: pageIndex - 1,
          }));
        } else {
          setResponse((prevState) => ({
            ...prevState,
            data: searchFlag
              ? [...apiResponse.data]
              : [...response.data, ...apiResponse.data],
            pageIndex: pageIndex,
          }));
        }
      } else {
        if (apiResponse.code === 401) {
          navigate("/login");
        }
      }
      setLoading(false);
    } catch (error) {}
  };

  const loadMore = () => {
    document.querySelector(
      ".ArrowIconLoadMore"
    ).innerHTML = `<i class="fa-solid fa-spinner-third fa-spin"></i>`;
    document.querySelector(".ArrowIconLoadMore").disabled = true;
    setTimeout(() => {
      document.querySelector(
        ".ArrowIconLoadMore"
      ).innerHTML = `<i class="fa-solid fa-arrow-down"></i>`;
      document.querySelector(".ArrowIconLoadMore").disabled = false;
    }, 1000);
    getAllData();
  };

  useEffect(() => {
    getAllData(1, "", "", "", false);
  }, []);

  /**
   * this function to delete region from DB and from DOM
   * using swal to confirm the delete
   * @param {number} id
   * @returns {void}
   */
  const deleteRegion = (id, e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        e.target.parentElement.parentElement.remove();
        await ApiCalling.DeleteRegion(id, localStorage.getItem("token"));
        swal("Poof! Your Region has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Region hasn't been deleted!");
      }
    });
  };

  const handleSearchByGov = (val) => {
    setResponse({
      ...response,
      pageIndex: 0,
      govId: val,
      areaid: "",
      data: [],
    });
    if (val !== "") getAreasOfGov(val);
    else clearAreaSelector();
    getAllData(1, val, "", "", true);
  };

  const handleSearchByArea = (val) => {
    setResponse({ ...response, pageIndex: 0, areaid: val, data: [] });
    getAllData(1, response.govId, val, "", true);
  };

  // this function to get the value of search input and set it to govId
  const getAreasOfGov = (id) => {
    clearAreaSelector();
    getAreaOfGov(id);
  };

  return (
    <React.Fragment>
      <div
        className={
          "container-fluid  justify-content-center d-flex align-items-center h-100 " +
          regionStyle.py_izi_5
        }
      >
        <div className="row w-100">
          <div className=" StudentPage">
            <div className="container  bg-white  rounded-4  pt-5 overflow-auto position-relative ">
              <div className="row">
                <div className="col-md-6 ">
                  <select
                    onChange={(e) => {
                      handleSearchByGov(e.target.value);
                    }}
                    style={{
                      width: "80%",
                      fontSize: "20px",
                    }}
                    name="Gov"
                    className="form-select px-3 py-2 m-auto mb-3 rounded-5"
                    id="selectorGovs"
                  >
                    <option value="" selected>
                      All Governorates
                    </option>

                    {/* <option value="1">Alexandria</option>
<option value="2">Assiut</option>
<option value="3">Aswan</option>
<option value="4">Beheira</option>
<option value="5">Bani Suef</option> */}
                    <option value="6">Cairo</option>
                    {/* <option value="7">Daqahliya</option>
<option value="8">Damietta</option>
<option value="9">Fayyoum</option>
<option value="10">Gharbiya</option> */}
                    <option value="11">Giza</option>
                    {/* <option value="12">Helwan</option>
<option value="13">Ismailia</option>
<option value="14">Kafr El-Sheikh</option>
<option value="15">Luxor</option>
<option value="16">Marsa Matrouh</option>
<option value="17">Minya</option>
<option value="18">Monofiya</option>
<option value="19">New Valley</option>
<option value="20">North Sinai</option>
<option value="21">Port Said</option>
<option value="22">Qalioubiya</option>
<option value="23">Qena</option>
<option value="24">Red Sea</option>
<option value="25">Sharqiya</option>
<option value="26">Sohag</option>
<option value="27">South Sinai</option>
<option value="28">Suez</option>
<option value="29">Tanta</option> */}
                  </select>
                </div>

                <div className="col-md-6 ">
                  <select
                    onChange={(e) => {
                      handleSearchByArea(e.target.value);
                    }}
                    style={{
                      width: "80%",
                      fontSize: "20px",
                    }}
                    name="Area"
                    className="form-select px-3 py-2 m-auto mb-3 rounded-5"
                    id="selectorAreas"
                  >
                    <option value="" selected>
                      All Areas
                    </option>
                  </select>
                </div>
              </div>

              <div className="row ">
                <div className="col-12">
                  <div
                    className={
                      regionStyle.tableContainer
                    } /*className="row  m-auto w-95"*/
                  >
                    <table className="px-3 m-auto w-100 table table-hover">
                      <thead className="sticky-md-top">
                        <tr className="table-light fw-bolder text-center">
                          <th>No.</th>
                          <th>Region</th>
                          <th>Area</th>
                          <th>Governorate</th>
                          <th className="">Edit</th>
                          <th className="">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading && (
                          <>
                            <tr className="placeholder-glow">
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-danger"></span>{" "}
                              </td>
                            </tr>
                            <tr className="placeholder-glow">
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-danger"></span>{" "}
                              </td>
                            </tr>
                            <tr className="placeholder-glow">
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-danger"></span>{" "}
                              </td>
                            </tr>
                          </>
                        )}
                        {response.data.map((region, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-center">
                                {arabicFlag ? region.arName : region.name}
                              </td>
                              <td className="text-center">
                                {arabicFlag ? region.arArea : region.area}
                              </td>
                              <td className="text-center">
                                {getTheGovernorate(region.govId, arabicFlag)}
                              </td>
                              <td className="text-center">
                                <Link
                                  to="./EditRegion"
                                  className="btn btn-warning"
                                  state={{ Region: region }}
                                >
                                  Edit
                                </Link>
                              </td>
                              {/* don't forget to remove data when you finished */}
                              <td className="text-center">
                                <button
                                  data={region.id}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteRegion(region.id, e);
                                  }}
                                  className="btn btn-danger rounded px-3 py-1"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row w-100 my-3 d-flex justify-content-center ">
                <div className="col-3  rounded-circle bg-primaryColor d-flex justify-content-center align-items-center">
                  <button
                    onClick={loadMore}
                    className={
                      regionStyle.SearchLink +
                      " ArrowIconLoadMore border border-0"
                    }
                  >
                    <i className="fa-solid fa-arrow-down"></i>
                  </button>
                </div>
                <div className=" col-3 rounded-circle bg-primaryColor d-flex justify-content-center align-items-center">
                  <Link to="./AddRegion" className={regionStyle.SearchLink}>
                    {" "}
                    <i className="fa-solid fa-plus"></i>
                  </Link>
                </div>
              </div>

              <div className="form-check form-switch position-absolute top-0 end-0 m-3">
                <input
                  className="form-check-input px-3 py-2"
                  type="checkbox"
                  id="arabicCheck"
                  onChange={(e) => {
                    setArabicFlag(e.target.checked);
                  }}
                />
                <label className="form-check-label" for="arabicCheck">
                  Arabic
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
function getTheGovernorate(id, arabicFlag) {
  switch (String(id)) {
    case "1":
      return arabicFlag ? "الإسكندرية" : "Alexandria";
    case "2":
      return arabicFlag ? "بورسعيد" : "Port Said";
    case "3":
      return arabicFlag ? "السويس" : "Suez";
    case "4":
      return arabicFlag ? "دمياط" : "Damietta";
    case "5":
      return arabicFlag ? "الدقهلية" : "Dakahlia";
    case "6":
      return arabicFlag ? "القاهرة" : "Cairo";
    case "7":
      return arabicFlag ? "الشرقية" : "Sharkia";
    case "8":
      return arabicFlag ? "القليوبية" : "Qalyubia";
    case "9":
      return arabicFlag ? "كفر الشيخ" : "Kafr El Sheikh";
    case "10":
      return arabicFlag ? "الغربية" : "Gharbia";
    case "11":
      return arabicFlag ? "الجيزة" : "Giza";
    case "12":
      return arabicFlag ? "المنوفية" : "Monufia";
    case "13":
      return arabicFlag ? "البحيرة" : "Beheira";
    case "14":
      return arabicFlag ? "الإسماعيلية" : "Ismailia";
    case "15":
      return arabicFlag ? "بني سويف" : "Beni Suef";
    case "16":
      return arabicFlag ? "الفيوم" : "Fayoum";
    case "17":
      return arabicFlag ? "المنيا" : "Minya";
    case "18":
      return arabicFlag ? "أسيوط" : "Asyut";
    case "19":
      return arabicFlag ? "سوهاج" : "Sohag";
    case "20":
      return arabicFlag ? "قنا" : "Qena";
    case "21":
      return arabicFlag ? "أسوان" : "Aswan";
    case "22":
      return arabicFlag ? "الأقصر" : "Luxor";
    case "23":
      return arabicFlag ? "البحر الأحمر" : "Red Sea";
    case "24":
      return arabicFlag ? "الوادي الجديد" : "New Valley";
    case "25":
      return arabicFlag ? "مرسى مطروح" : "Matrouh";
    case "26":
      return arabicFlag ? "شمال سيناء" : "North Sinai";
    case "27":
      return arabicFlag ? "جنوب سيناء" : "South Sinai";
    default:
      return arabicFlag ? "المحافظة غير معروفة" : "Unknown Governorate";
  }
}


/* Helper Functions */
function creatDefaultELementInSelector(text) {
  let option = document.createElement("option");
  option.value = "";
  option.selected = true;
  option.disabled = true;
  option.innerHTML = text;
  return option;
}

// this function to clear the options of selector when we change the value of selector
const clearAreaSelector = () => {
  const selector = document.querySelector("#selectorAreas");
  while (selector.firstChild) {
    selector.removeChild(selector.firstChild);
  }
};

// this function to append Child to Areas Selector
const appendChildToAreasSelector = (response) => {
  let areas = response;
  let selector = document.querySelector("#selectorAreas");
  let option = creatDefaultELementInSelector("All available Areas");
  selector.append(option);
  selector.style.display = "block";
  areas.forEach((area) => {
    let option = document.createElement("option");
    option.value = area.id;
    option.innerHTML = area.name;
    selector.appendChild(option);
  });
};

function getAreaOfGov(id) {
  ApiCalling.getAreasOfGov(id).then((response) => {
    appendChildToAreasSelector(response);
  });
}

export default Region;
