import React, { Component } from "react";
import ApiCalling from "../../../Js Files/apiCalling";
import swal from "sweetalert";

export default class AparmentSlider extends Component {
  state = {
    images: this.props.flatImages,
    apartmentId: this.props.apartmentId,
  };
  ReturnCoutrsolFirst = () => {
    let firstImage = document.querySelector(".carousel-item");
    firstImage.classList.add("active");
    // make first indicator active
    let firstIndicator = document.querySelector(".carousel-indicators button");
    firstIndicator.classList.add("active");
  };
  dleteImage = (e, index) => {
    let imageId = document
      .querySelector(`.image${index}`)
      .getAttribute("data_image_id");
    swal({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this image",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let updatedImages = this.state.images.filter((item) => {
          return item.imageId != imageId;
        });
        if (index == this.state.images.length - 1) this.ReturnCoutrsolFirst();
        this.setState({ images: updatedImages });
        await ApiCalling.deleteImage(imageId, localStorage.getItem("token"));
        swal("Poof! Your Image has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Image hasn't been deleted!");
      }
    });
  };
  render() {
    let { DeleteImageFeature, DetailsFlagPage, isEditImages,setIsEditImages } = this.props;
    return (
      <React.Fragment>
        <div
          id="carouselExampleWhite"
          className={
            (DetailsFlagPage ? "h-100 " : " ") +
            "carousel carousel-white hide slide"
          }
          style={{
            width: "100%",
            height: "200px",
          }}
        >
          <div className="carousel-indicators">
            {this.props.flatImages.length > 0 ? (
              this.props.flatImages.map((item, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    id={"image" + index}
                    data-bs-target="#carouselExampleWhite"
                    data-bs-slide-to={index}
                    style={{ width: "20px" }}
                    className={
                      index == 0
                        ? "active rounded-circle py-2 bg-primColor"
                        : "rounded-circle py-2 bg-primColor"
                    }
                    aria-current="true"
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                );
              })
            ) : (
              <button
                type="button"
                data-bs-target="#carouselExampleWhite"
                data-bs-slide-to="0"
                style={{ width: "20px" }}
                className="active rounded-circle py-2 bg-primColor"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
            )}
          </div>
          <div className="carousel-inner h-100">
            {this.props.flatImages.length > 0 ? (
              this.props.flatImages.map((item, index) => (
                <div
                  key={index}
                  id={"image" + index}
                  className={
                    index === 0
                      ? "carousel-item h-100 active"
                      : "carousel-item h-100"
                  }
                  data-bs-interval={(index + 1) * 1000}
                >
                  {DeleteImageFeature && (
                    <div
                      className="position-absolute top-0 end-0 text-white  m-2"
                      data-bs-theme="light"
                    >
                      <button
                        type="button"
                        onClick={(e) => this.dleteImage(e, index)}
                        className={
                          "btn btn-danger text-white rounded-circle image" +
                          index
                        }
                        aria-label="Close"
                        data_image_id={item.imageId}
                      >
                        <i className="fa-solid fa-x fs-5"></i>
                      </button>
                    </div>
                  )}
                  {!isEditImages && (
                    <div
                      className="position-absolute top-0 start-0 text-white  m-2"
                      data-bs-theme="light"
                    >
                      <button
                        type="button"
                        onClick={(e) => setIsEditImages(!isEditImages)}
                        className={
                          "btn btn-success text-white rounded-circle image" +
                          index
                        }
                        aria-label="Close"
                        data_image_id={item.imageId}
                      >
                        <i class="fa-regular fa-pen-to-square"></i>{" "}
                      </button>
                    </div>
                  )}
                  <img
                    src={item.url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    className="w-100 h-100 d-block"
                    alt="apartment and flat"
                  />
                </div>
              ))
            ) : (
              <div
                className="carousel-item h-100 active"
                data-bs-interval="10000"
              >
                <img
                  src="Images/RandomBuilding.jpg"
                  className="w-100 h-100 d-block"
                  alt="apartment and flat"
                />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
