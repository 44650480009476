import React, { Component } from 'react'
import  "./styleNavBar.css" 
import { Link } from 'react-router-dom'
import ApiCalling from '../../Js Files/apiCalling'
import { endACurrentSession } from '../../Js Files/CheckUser'
import Logo from "../../Images/Logo.png"
export default class Navbar extends Component {

// specify style for nav item when refresh page
specifyStyleForNav=()=>{
  document.querySelectorAll(".nav-link").forEach((element)=>{
    if(window.location.href.includes(element.innerText)){
      document.querySelectorAll(".nav-link").forEach((element)=>{
        element.classList.remove("selectedNavElement")
      })
      element.classList.add("selectedNavElement");
    }
  })
}
// change style for nav item when click on it
  changeStyleForNavItem=()=>{
    document.querySelectorAll(".nav-link").forEach((element)=>{
      element.addEventListener("click",()=>{
        document.querySelectorAll(".nav-link").forEach((element)=>{
          element.classList.remove("selectedNavElement")
        })
        element.classList.add("selectedNavElement");
      })
    
    })
  }
  // logOut function 
  logOut=()=>{
    ApiCalling.logoutAdmin(localStorage.getItem("token"));
    localStorage.removeItem("token");
    endACurrentSession();
    window.location.href="/login";

  }
  componentDidMount(){
    this.specifyStyleForNav()
    this.changeStyleForNavItem()
  }
 render() {
    return <React.Fragment><nav className="navbar navbar-expand-lg  bg-white">
    <div className="container-fluid mx-3 d-flex justify-content-between ">
      <Link className="navbar-brand" to="/Apartment">
        <img src={Logo} style={{
          width: '150px'
        
        }} alt="logo website " />
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span> 
      </button>
      <div className="collapse navbar-collapse fs-5" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item me-5">
            <Link className="nav-link active selectedNavElement " aria-current="page" to="/Apartment">Apartment</Link>
          </li>
          <li className="nav-item me-5">
            <Link className="nav-link active" aria-current="page" to="/Area">Area</Link>
          </li>
          <li className="nav-item me-5">
            <Link className="nav-link active" aria-current="page" to="/Region">Region</Link>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="">
            <button onClick={this.logOut} className='btn btn-success bg-primColor'> Logout</button>
          </li>
        </ul>
      </div>
      
    </div>
  </nav>
  </React.Fragment>
  }
}
