import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiCalling from "../../../Js Files/apiCalling";
import AparmentSlider from "../ApartmentSliderComponent/AparmentSlider";
import ErrorPage from "../../ErrorPage/ErrorPage";
import { useFormik } from "formik";
import * as Yup from "yup";
import ImageUpload from "../ImageUploader/ImageUploader";
import ImageModuleContainer from "../ImageReorderModule/ImageModuleContainer";

function EditApart() {
  const location = useLocation();
  const navigate = useNavigate();
  let [apartment, setApartment] = useState({ ...location.state.apartment });
  const [images, setImages] = useState([...apartment.pictures])
  let [arApartment] = useState({ ...location.state.arApartment });
  const [loading, setLoading] = useState(false);
  const [addressError, setAddressError] = useState({
    governateError: "",
    areaError: "",
    regionError: "",
  });
  const [isEditImages, setIsEditImages] = useState(false)

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [loadingImages, setLoadingImages] = useState(false);

  const [errorMessageImages, setErrorMessageImages] = useState("");
  const [successMessageImages, setSuccessMessageImages] = useState("");

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(
      "You have to enter the title of the apartment"
    ),
    arTitle: Yup.string().required("يجب ادخال عنوان الشقة"),
    floor: Yup.string().required(
      "You have to enter the number of the floor of the apartment"
    ),
    arFloor: Yup.string().required("يجب ادخال رقم الدور الخاص بالشقة"),
    condition: Yup.string().required(
      "You have to enter the condition of the apartment"
    ),
    arCondition: Yup.string().required("يجب ادخال حالة الشقة"),
    description: Yup.string().required(
      "You have to enter the description of the apartment"
    ),
    arDescription: Yup.string().required("يجب ادخال وصف الشقة"),
    price: Yup.string()
    .required("You have to enter the price of the apartment")
    .matches(/^[0-9,]*$/, "You have to enter a valid price"),
    ApartmentType: Yup.string().required(
      "You have to select an apartment type"
    ),
    images: Yup.array().test(
      "fileSize",
      "One or more images exceed the 5MB size limit",
      (images) => images.every((img) => img.file.size <= 5 * 1024 * 1024)
    ),
  });
  const formik = useFormik({
    initialValues: {
      title: apartment.title,
      floor: apartment.floor,
      condition: apartment.condition,
      description: apartment.description,
      arTitle: arApartment.title,
      arFloor: arApartment.floor,
      arCondition: arApartment.condition,
      arDescription: arApartment.description,
      price: apartment.price,
      ApartmentType: getTheApartmentType(apartment.type),
      images: [],
    },
    validationSchema: validationSchema,
    onSubmit: sendData,
  });
  const validateAddress = () => {
    let isValid = true;
    if (!apartment.govId) {
      addressError.governateError = "you have to enter a Governorate";
      isValid = false;
    } else {
      addressError.governateError = "";
    }
    if (!apartment.areaId) {
      addressError.areaError = "you have to enter a Area";
      isValid = false;
    } else {
      addressError.areaError = "";
    }
    if (!apartment.regionId) {
      addressError.regionError = "you have to enter a Region";
      isValid = false;
    } else {
      addressError.regionError = "";
    }
    setAddressError({ ...addressError });
    return isValid;
  };
  async function sendData(values) {
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    if (values.images.length !== 0) {
      setLoadingImages(true);
      setErrorMessageImages("");
      setSuccessMessageImages("");
    }
    if (validateAddress()) {
      await editApartment(values);
      if (values.images.length !== 0)
        await uploadImages(values.images, apartment.id);
    }
  }
  async function editApartment(values) {
    try {
      let response = await ApiCalling.editApartment(
        apartment.id,
        values.title,
        values.description,
        apartment.regionId,
        values.floor,
        values.condition,
        values.price,
        0,
        values.images,
        values.ApartmentType,
        values.arTitle,
        values.arDescription,
        values.arFloor,
        values.arCondition,
        localStorage.getItem("token")
      );
      console.log(
        apartment.id,
        values.title,
        values.description,
        apartment.regionId,
        values.floor,
        values.condition,
        values.price,
        0,
        values.images,
        values.ApartmentType,
        values.arTitle,
        values.arDescription,
        values.arFloor,
        values.arCondition,
        localStorage.getItem("token")
      );
      console.log(response);
      if (response.code === 200) {
        setLoading(false);
        setSuccessMessage("Apartment edited successfully");
        setErrorMessage("");
        if (values.images.length === 0) {
          setTimeout(() => {
            navigate("/Apartment");
          }, 2000);
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("An error occurred while editing the apartment");
      setSuccessMessage("");
    }
  }
  async function uploadImages(files, ApartmentId) {
    try {
      let response = await ApiCalling.addImages(
        files,
        ApartmentId,
        localStorage.getItem("token")
      );
      console.log(response);
      if (response.code === 200) {
        setLoadingImages(false);
        setSuccessMessageImages(response.message);
        setErrorMessageImages("");

        setTimeout(() => {
          navigate("/Apartment");
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessageImages("An error occurred while editing the apartment");
      setSuccessMessageImages("");
    }
  }
  useEffect(() => {
    fillAllInputs();
  }, []);

  async function fillAllInputs() {
    (async () => {
      await getAreasOfGov({ target: { value: apartment.govId } });
      await getRegionOfArea({ target: { value: apartment.areaId } }).then(
        (val) => {
          if (val === undefined) {
            setTimeout(() => {
              document.querySelector("#governorates").value = apartment.govId;
              document.querySelector("#selectorAreas").value = apartment.areaId;
              document.querySelector("#selectorRegions").value =
                apartment.regionId;
            }, 1500);
          }
        }
      );
    })();
  }

  const getAreasOfGov = async (e) => {
    setApartment({ ...apartment, govId: e.target.value }); // set the governorate id
    clearAreaSelector();
    ApiCalling.getAreasOfGov(e.target.value).then((response) => {
      appendChildToAreasSelector(response);
    });
  };

  const getRegionOfArea = async (e) => {
    setApartment({ ...apartment, areaId: e.target.value }); // set the area id
    clearRegionsSelector();
    ApiCalling.getRegionsOfArea(
      e.target.value,
      localStorage.getItem("token")
    ).then((response) => {
      appendChildToRegionsSelector(response);
    });
  };

  if (!apartment) {
    return <ErrorPage />;
  }

  return (
    <div className="container-fluid p-5 justify-content-center d-flex align-items-center">
      <div className="rounded py-3 w-md-50 w-sm-100 d-flex justify-content-center flex-wrap position-relative">
        <div className="row w-100">
          <h2
            className="text-center text-primColor bg-white rounded-5  fw-bolder m-auto"
            style={{ width: "fit-content" }}
          >
            Edit Apartment
          </h2>
        </div>
        <div className="row w-100">
          <div className="m-auto my-3 col-lg-4 col-md-12">
            <AparmentSlider
              apartmentId={apartment.id}
              DeleteImageFeature={true}
              isEditImages={isEditImages}
              setIsEditImages={setIsEditImages}
              DetailsFlagPage={false}
              flatImages={images}
            />
          </div>
        </div>
        <form id="EditApartment" className="row" onSubmit={formik.handleSubmit}>
          <fieldset className="bg-white shadow col-lg-5 col-sm-10 m-auto my-2 rounded-5 py-5">
            <legend className="text-primColor fw-bold">
              Basic information
            </legend>
            <div className="form-group  mt-2">
              <label htmlFor="title" className="fw-bold">
                Title:
              </label>
              <input
                placeholder="Enter title"
                type="text"
                className="form-control"
                id="title"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
              />
              {formik.errors.title && formik.touched.title && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.title}
                </div>
              )}
            </div>
            <div className="form-group mt-2">
              <label htmlFor="floor" className="fw-bold">
                Floor:
              </label>
              <input
                placeholder="Enter floor number"
                type="text"
                className="form-control"
                id="floor"
                name="floor"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.floor}
              />
              {formik.errors.floor && formik.touched.floor && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.floor}
                </div>
              )}
            </div>
            <div className="form-group mt-2">
              <label htmlFor="condition" className="fw-bold">
                Apartment Condition:
              </label>
              <input
                placeholder="Enter apartment condition"
                type="text"
                className="form-control"
                id="condition"
                name="condition"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.condition}
              />
              {formik.errors.condition && formik.touched.condition && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.condition}
                </div>
              )}
            </div>
            <div className="form-group col-12 mt-2">
              <label htmlFor="description" className="fw-bold">
                Apartment Description:
              </label>
              <textarea
                placeholder="Enter apartment description"
                className="form-control"
                id="description"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.errors.description && formik.touched.description && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.description}
                </div>
              )}
            </div>
          </fieldset>
          <fieldset
            dir="rtl"
            className="shadow bg-white col-lg-5 col-sm-10 m-auto my-2 rounded-5 py-5"
          >
            <legend className="text-primColor fw-bold">
              المعلومات الاساسية
            </legend>
            <div className="form-group mt-2">
              <label htmlFor="arTitle" className="fw-bold">
                العنوان:
              </label>
              <input
                placeholder="ادخل العنوان"
                type="text"
                className="form-control"
                id="arTitle"
                name="arTitle"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.arTitle}
              />
              {formik.errors.arTitle && formik.touched.arTitle && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.arTitle}
                </div>
              )}
            </div>
            <div className="form-group mt-2">
              <label htmlFor="arFloor" className="fw-bold">
                الدور:
              </label>
              <input
                placeholder="ادخل رقم الدور"
                type="text"
                className="form-control"
                id="arFloor"
                name="arFloor"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.arFloor}
              />
              {formik.errors.arFloor && formik.touched.arFloor && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.arFloor}
                </div>
              )}
            </div>
            <div className="form-group mt-2">
              <label htmlFor="arCondition" className="fw-bold">
                حالة الشقة:
              </label>
              <input
                placeholder="ادخل حالة الشقة"
                type="text"
                className="form-control"
                id="arCondition"
                name="arCondition"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.arCondition}
              />
              {formik.errors.arCondition && formik.touched.arCondition && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.arCondition}
                </div>
              )}
            </div>
            <div className="form-group mt-2">
              <label htmlFor="arDescription" className="fw-bold">
                وصف الشقة:
              </label>
              <textarea
                placeholder="ادخل وصف الشقة"
                className="form-control"
                id="arDescription"
                name="arDescription"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.arDescription}
              />
              {formik.errors.arDescription && formik.touched.arDescription && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.arDescription}
                </div>
              )}
            </div>
          </fieldset>

          <fieldset className="bg-white shadow col-10 m-auto my-3 rounded-5 py-3 px-5">
            <div className="form-group mt-2">
              <label htmlFor="price" className="fw-bold">
                Price:
              </label>
              <input
                placeholder="Enter price"
                type="text"
                className="form-control"
                id="price"
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.price && formik.touched.price && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.price}
                </div>
              )}
            </div>

            <div className="form-group  mt-2 mb-4">
              <label htmlFor="governorates" className="fw-bold">
                Govenorate
              </label>

              <select
                id="governorates"
                onChange={(e) => getAreasOfGov(e)}
                name="govenorate"
                className="form-control"
              >
                <option value="" disabled selected>
                  Choose governorates{" "}
                </option>
                <option value="1">Alexandria</option>
  <option value="2">Port Said</option>
  <option value="3">Suez</option>
  <option value="4">Damietta</option>
  <option value="5">Dakahlia</option>
  <option value="6">Cairo</option>
  <option value="7">Sharkia</option>
  <option value="8">Qalyubia</option>
  <option value="9">Kafr El Sheikh</option>
  <option value="10">Gharbia</option>
  <option value="11">Giza</option>
  <option value="12">Monufia</option>
  <option value="13">Beheira</option>
  <option value="14">Ismailia</option>
  <option value="15">Beni Suef</option>
  <option value="16">Fayoum</option>
  <option value="17">Minya</option>
  <option value="18">Asyut</option>
  <option value="19">Sohag</option>
  <option value="20">Qena</option>
  <option value="21">Aswan</option>
  <option value="22">Luxor</option>
  <option value="23">Red Sea</option>
  <option value="24">New Valley</option>
  <option value="25">Matrouh</option>
  <option value="26">North Sinai</option>
  <option value="27">South Sinai</option>

              </select>

              {addressError.governateError && (
                <div className="alert  alert-danger" role="alert">
                  {addressError.governateError}
                </div>
              )}
            </div>
            <div className="form-group  mt-2">
              <label htmlFor="selectorAreas" className="fw-bold">
                Area Name
              </label>
              <select
                id="selectorAreas"
                onChange={(e) => getRegionOfArea(e)}
                name="Area"
                className="form-control"
              ></select>

              {addressError.areaError && (
                <div className="alert  alert-danger" role="alert">
                  {addressError.governateError}
                </div>
              )}
            </div>
            <div className="form-group  mt-2">
              <label htmlFor="selectorRegions" className="fw-bold">
                Region Name
              </label>
              <select
                id="selectorRegions"
                name="Region"
                className="form-control"
                onChange={(e) =>
                  setApartment({ ...apartment, regionId: e.target.value })
                }
              ></select>

              {addressError.regionError && (
                <div className="alert  alert-danger" role="alert">
                  {addressError.regionError}
                </div>
              )}
            </div>
            <div className="form-group mt-2 ">
              <ImageUpload formik={formik} loadingImages={loadingImages} />
              {formik.errors.images && formik.touched.images && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.images}
                </div>
              )}
              {loadingImages && (
                <div className="alert alert-warning text-center" role="status">
                  that takes some time to upload images please don't close the
                  page
                </div>
              )}
              {successMessageImages && (
                <div className="alert alert-success text-center">
                  {successMessageImages}
                </div>
              )}

              {errorMessageImages && (
                <div className="alert alert-danger text-center">
                  {errorMessageImages}
                </div>
              )}
            </div>

            <div className="d-flex rounded-5 py-2 m-auto bg-dark-subtle justify-content-around my-2">
              <div className="form-group mt-2">
                <input
                  type="radio"
                  id="BuyRadio"
                  name="ApartmentType"
                  value="2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.ApartmentType === "2"}
                />
                <label htmlFor="BuyRadio">Buy</label>
              </div>

              <div className="form-group mt-2">
                <input
                  type="radio"
                  id="RentRadio"
                  name="ApartmentType"
                  value="1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.ApartmentType === "1"}
                />
                <label htmlFor="RentRadio">Rent</label>
              </div>
              {formik.errors.ApartmentType && (
                <div
                  className="alert text-center mt-2 alert-danger"
                  role="alert"
                >
                  {formik.errors.ApartmentType}
                </div>
              )}
            </div>

            <div className="d-flex justify-content-center flex-column flex-wrap mt-3">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-success bg-primColor fw-bolder fs-5 submitButton"
              >
                {loading ? "wait..." : "Edit Apartment"}
              </button>
              {errorMessage && (
                <div
                  className="alert AlertMessage  text-center mt-2 alert-danger  m-auto"
                  role="alert"
                >
                  {errorMessage}
                </div>
              )}

              {successMessage && (
                <div
                  className="alert SuccessMessage  text-center mt-2 alert-success  m-auto"
                  role="alert"
                >
                  {successMessage}
                </div>
              )}
            </div>

            <Link
              to="/Apartment"
              className="btn btn-outline-success w-100 mt-3 "
            >
              return to Apartment
            </Link>
          </fieldset>
        </form>
      </div>
      {isEditImages ? (
        <ImageModuleContainer
          images={images}
          setIsEditImages={setIsEditImages}
          setImages={setImages}
          apartmentId={apartment.id}
        />
      ) : null}
    </div>
  );
}

export default EditApart;

/*Helper Function */

// Clear options of selector when changing value
const clearAreaSelector = () => {
  const selector = document.querySelector("#selectorAreas");
  while (selector.firstChild) {
    selector.removeChild(selector.firstChild);
  }
};

// Clear options of selector when changing value
const clearRegionsSelector = () => {
  const selectorRegions = document.querySelector("#selectorRegions");
  while (selectorRegions.firstChild) {
    selectorRegions.removeChild(selectorRegions.firstChild);
  }
};

function creatDefaultELementInSelector(text) {
  let option = document.createElement("option");
  option.value = "";
  option.selected = true;
  option.disabled = true;
  option.innerHTML = text;
  return option;
}

// Append child to areas selector
const appendChildToAreasSelector = (response) => {
  let areas = response;
  let selector = document.querySelector("#selectorAreas");
  clearAreaSelector();
  let option = creatDefaultELementInSelector("All available Areas");
  selector.append(option);
  selector.style.display = "block";
  areas.forEach((area) => {
    let option = document.createElement("option");
    option.value = area.id;
    option.innerHTML = area.name;
    selector.appendChild(option);
  });
};

// Append child to regions selector
const appendChildToRegionsSelector = (response) => {
  let regions = response;
  let selector = document.querySelector("#selectorRegions");
  creatDefaultELementInSelector("All available Regions");
  selector.style.display = "block";
  regions?.forEach((region) => {
    let option = document.createElement("option");
    option.value = region.id;
    option.innerHTML = region.name;
    selector.appendChild(option);
  });
};

function getTheApartmentType(id) {
  switch (String(id)) {
    case "Rent":
      return "1";
    case "Buy":
      return "2";
    default:
      return "0";
  }
}
