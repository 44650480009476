// ImageReorderModule.jsx
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Reordering function
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Grid styles
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid,
  margin: `0 ${grid}px ${grid}px 0`,
  background: isDragging ? 'lightgreen' : '#fff',
  border: '1px solid #ccc',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: grid,
  background: isDraggingOver ? 'lightblue' : '#f8f8f8',
  border: '1px solid #ccc',
});

// Main component
const ImageReorderModule = ({ images, onSave }) => {
  const [items, setItems] = useState(images);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(items, result.source.index, result.destination.index);
    setItems(newItems);
  };

  const handleSave = () => {
    onSave(items);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable key={item.imageId} draggableId={item.imageId.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <img src={item.url} alt={`Item ${item.imageId}`} style={{ width: '100px', height: '100px' }} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button className='btn btn-success my-2' onClick={(images)=>handleSave(images)}>Save</button>
    </div>
  );
};

export default ImageReorderModule;
