import React, { useState } from "react";
import { useNavigate } from "react-router";
import loginStyle from "./Login.module.css";
import ApiCalling from "../../Js Files/apiCalling";
import { startANewSession } from "../../Js Files/CheckUser";

const BaseURl = "https://api.summitegypt.com";
// const AdminBaseURL = `${BaseURl}/accounts/admins`;

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    // Basic validation
    if (!username || !password) {
      setError("Please fill in all fields.");
      setLoading(false);
      return;
    }

    const user = {
      EMAILORPHONE: username,
      PASSWORD: password,
      Rememberme: rememberMe ? 1 : 0,
    };

    // Call the API to log in the admin
    const result = await ApiCalling.loginAdmin(user);

    if (result.code == undefined) {
      // Save the token to local storage
      localStorage.setItem("token", result.token);

      // start new session which add some date in localstorage like start Date which we will use to check if this user session
      startANewSession();
      setLoading(false);
      // Redirect to the root path ('/') using useNavigate
      navigate("/Apartment");
    } else {
      // User authentication failed
      if (result.message) {
        setError(result.message );
        setLoading(false);

      } else {
        setError(result.message || "An error occurred during login.");
        setLoading(false);
      }
    }
  };

  return (
    <div className={loginStyle.container + " bg-transparent"}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center align-items-center mb-3">
            <img
              src="Images/LogoWhite.png"
              style={{ maxWidth: "300PX", maxHeight: "225px" }}
              alt="logo website "
            />
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-md-4">
            <div className={loginStyle.card}>
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Login</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="userName">Username:</label>
                      <input
                        type="text"
                        id="userName"
                        className="form-control"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password:</label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                      />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Remember Me
                      </label>
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <button type="submit" disabled={loading} className="btn btn-primary">
                      {loading? "loading..." :"Login"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
