import React, { useState, useEffect } from "react";
import apartmentStyle from "./Apartment.module.css";
import { Link, useNavigate } from "react-router-dom";
import ApiCalling from "../../Js Files/apiCalling";
import swal from "sweetalert";

const Apartment = () => {
  const navigate = useNavigate();
  const [response, setResponse] = useState({
    regionId: "",
    title: "",
    pageIndex: 1,
    Type: "",
    areaid: "",
    govId: "",
    data: [],
  });
  const [arabicApartment, setArabicApartment] = useState([]);
  const [arabicFlag, setArabicFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const getAllData = async (
    title = response.title,
    pageIndex = response.pageIndex,
    searchFlag = false
  ) => {
    const newPageIndex = pageIndex + 1;
    try {
      const apiResponse = await ApiCalling.getAllApartments(
        `?regionId=${response.regionId}&title=${title}&pageIndex=${pageIndex}&Type=${response.Type}&areaid=${response.areaid}&govId=${response.govId}`
      );
      const apiResponseArabic = await ApiCalling.getAllApartments(
        `?regionId=${response.regionId}&title=${title}&pageIndex=${pageIndex}&Type=${response.Type}&areaid=${response.areaid}&govId=${response.govId}&language=ar`
      );
      if (apiResponse.data) {
        if (apiResponse.data.length === 0) {
          setResponse({
            ...response,
            data: searchFlag
              ? [...apiResponse.data]
              : [...response.data, ...apiResponse.data],
            pageIndex: newPageIndex - 1,
          });
          setArabicApartment(
            searchFlag
              ? [...apiResponseArabic.data]
              : [...arabicApartment, ...apiResponseArabic.data]
          );
        } else {
          setResponse({
            ...response,
            data: searchFlag
              ? [...apiResponse.data]
              : [...response.data, ...apiResponse.data],
            pageIndex: newPageIndex,
          });
          setArabicApartment(
            searchFlag
              ? [...apiResponseArabic.data]
              : [...arabicApartment, ...apiResponseArabic.data]
          );
        }
      } else {
        if (apiResponse.code === 401) {
          navigate("/login");
        }
      }
      setLoading(false);
    } catch (error) {}
  };

  const loadMore = () => {
    document.querySelector(
      ".ArrowIconLoadMore"
    ).innerHTML = `<i class="fa-solid fa-spinner-third fa-spin"></i>`;
    document.querySelector(".ArrowIconLoadMore").disabled = true;
    setTimeout(() => {
      document.querySelector(
        ".ArrowIconLoadMore"
      ).innerHTML = `<i class="fa-solid fa-arrow-down"></i>`;
      document.querySelector(".ArrowIconLoadMore").disabled = false;
    }, 1000);
    getAllData();
  };

  useEffect(() => {
    getAllData("", 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteApartment = async (id, e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        e.target.parentElement.parentElement.remove();
        await ApiCalling.deleteAppartment(id, localStorage.getItem("token"));
        swal("Poof! Your Apartment has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Apartment hasn't been deleted!");
      }
    });
  };

  const handleSearchByTitle = (e) => {
    let input = e.target.querySelector("input");
    setResponse({ ...response, pageIndex: 1, title: input.value, data: [] });
    getAllData(input.value, 1, true);
  };

  return (
    <React.Fragment>
      <div
        className={
          "container-fluid py_izi_5  bg-transparent justify-content-center d-flex align-items-center h-100 " +
          apartmentStyle.py_izi_5
        }
        style={{ backgroundColor: "transparent" }}
      >
        <div className="row w-100">
          <div className="w-100 h-100 ">
            <div className="container w-90 bg-white  rounded-4  pt-5 overflow-auto position-relative ">
              <div className="row">
                <div className="col-12 ">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearchByTitle(e);
                    }}
                    dir="rtl"
                    id="RentPageSearch"
                    className="d-flex w-100 justify-content-center align-items-cnenter"
                  >
                    <div className="w-75 position-relative text-secondary">
                      <input
                        name="searchElement"
                        type="text"
                        className="form-control mb-3 form-label mb-0 rounded-5 p-2 pe-3"
                        placeholder="search"
                      />
                      <i className="fa-solid fa-magnifying-glass pb-3 position-absolute top-50 translate-middle-y ms-3 start-0"></i>
                    </div>
                  </form>
                </div>
              </div>
              <div className={apartmentStyle.tableContainer}>
                <table className="px-3 m-auto w-100 table table-hover">
                  <thead className="sticky-md-top">
                    <tr className="table-light fw-bolder text-center">
                      <th>No.</th>
                      <th>Region</th>
                      <th>Area</th>
                      <th>Title</th>
                      <th>Price</th>
                      <th>Description</th>
                      <th className="">Details</th>
                      <th className="">Edit</th>
                      <th className="">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <>
                        <tr className="placeholder-glow">
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8 bg-danger"></span>{" "}
                          </td>
                        </tr>
                        <tr className="placeholder-glow">
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8 bg-danger"></span>{" "}
                          </td>
                        </tr>
                        <tr className="placeholder-glow">
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span className="rounded placeholder py-3 col-8 bg-danger"></span>{" "}
                          </td>
                        </tr>
                      </>
                    )}
                    {response.data.map((apartment, index) => {
                      apartment = arabicFlag
                        ? arabicApartment[index]
                        : apartment;
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{apartment.region}</td>
                          <td className="text-center">{apartment.area}</td>
                          <td className="text-center">{apartment.title}</td>
                          <td className="text-center">{apartment.price.toLocaleString()}</td>
                          <td className="text-center">
                            {apartment.description
                              .split(" ")
                              .slice(0, 5)
                              .join(" ")}
                            .....
                          </td>
                          <td className="text-center">
                            <Link
                              to="./ShowApart"
                              state={{ apartment: apartment ,arApartment:arabicApartment[index] }}
                              className="btn btn-success bg-primColor"
                            >
                              Details
                            </Link>
                          </td>
                          <td className="text-center">
                            <Link
                              to="./EditApart"
                              className="btn btn-warning"
                              state={{ apartment: apartment,arApartment:arabicApartment[index] }}
                            >
                              Edit
                            </Link>
                          </td>
                          <td className="text-center">
                            <button
                              data={apartment.id}
                              onClick={async (e) => {
                                e.preventDefault();
                                await deleteApartment(apartment.id, e);
                              }}
                              className="btn btn-danger rounded px-3 py-1"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="row w-100 my-3 d-flex justify-content-center ">
                <div className="col-3  rounded-circle bg-primaryColor d-flex justify-content-center align-items-center">
                  <button
                    onClick={loadMore}
                    className={
                      apartmentStyle.SearchLink +
                      " ArrowIconLoadMore border border-0"
                    }
                  >
                    <i className="fa-solid fa-arrow-down"></i>
                  </button>
                </div>
                <div className=" col-3 rounded-circle bg-primaryColor d-flex justify-content-center align-items-center">
                  <Link to="./AddApart" className={apartmentStyle.SearchLink}>
                    {" "}
                    <i className="fa-solid fa-plus"></i>
                  </Link>
                </div>
              </div>

              <div className="form-check form-switch position-absolute top-0 end-0 m-3">
                <input
                  className="form-check-input px-3 py-2"
                  type="checkbox"
                  id="arabicCheck"
                  onChange={(e) => {
                    setArabicFlag(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="arabicCheck">
                  Arabic
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Apartment;
