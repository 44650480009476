import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import checkIfSessionEnd from "../../Js Files/CheckUser";

export default function ProtectedRoute(props) {
   const [sessionEnded, setSessionEnded] = useState(false);
   const [flagToSaythatWeFinsishCheckUser, setflagToSaythatWeFinsishCheckUser] = useState(false)
   useEffect(() => {
      checkIfSessionEnd()
         .then((sessionEnded) => {
            setSessionEnded(sessionEnded);
            setflagToSaythatWeFinsishCheckUser(true)
         })
         .catch((error) => {
            // Handle any errors that occur during the session check
            console.error("Error checking session:", error);
         });
   }, []);
   if (flagToSaythatWeFinsishCheckUser) {

      if (!sessionEnded) {
         return <Navigate to="/login" />;
      } else {
         return props.children;
      }
   }
}
