import React, { useEffect } from 'react';
import startWork from '../../../Js Files/Forms';
import ApiCalling from '../../../Js Files/apiCalling';
import { Link } from 'react-router-dom';

function AddRegion() {
  useEffect(() => {
    startWork();
  }, []);

  // this function to get the value of search input and set it to govId
 const getAreasOfGov = (e) => {
  clearAreaSelector();
  ApiCalling.getAreasOfGov(e.target.value).then((response) => {
    appendChildToAreasSelector(response);
  });
  
}  
  return (

    <div className="container-fluid p-5 justify-content-center d-flex align-items-center  ">
      <div className="row bg-white rounded py-3 shadow" >
        <div>
          <h2 className='text-center text-primColor fw-bolder'>Add Region</h2>
        </div>

        <form id='AddRegion'>

          <div className="form-group mt-2">
            <label htmlFor="title" className='fw-bold'>Govenorate</label>

            <select onChange={(e)=>getAreasOfGov(e)} id="governorates" name='governorate' className='form-control'>
              <option value="" disabled selected>Choose governorates </option>
              <option value="1">Alexandria</option>
  <option value="2">Port Said</option>
  <option value="3">Suez</option>
  <option value="4">Damietta</option>
  <option value="5">Dakahlia</option>
  <option value="6">Cairo</option>
  <option value="7">Sharkia</option>
  <option value="8">Qalyubia</option>
  <option value="9">Kafr El Sheikh</option>
  <option value="10">Gharbia</option>
  <option value="11">Giza</option>
  <option value="12">Monufia</option>
  <option value="13">Beheira</option>
  <option value="14">Ismailia</option>
  <option value="15">Beni Suef</option>
  <option value="16">Fayoum</option>
  <option value="17">Minya</option>
  <option value="18">Asyut</option>
  <option value="19">Sohag</option>
  <option value="20">Qena</option>
  <option value="21">Aswan</option>
  <option value="22">Luxor</option>
  <option value="23">Red Sea</option>
  <option value="24">New Valley</option>
  <option value="25">Matrouh</option>
  <option value="26">North Sinai</option>
  <option value="27">South Sinai</option>

            </select>

            <div class="alert d-none alert-danger" role="alert">
              you have to enter a value </div>
          </div>
          <div className="form-group mt-2">
            <label htmlFor="title" className='fw-bold'>Area Name</label>
            <select id="selectorAreas" name='Area' className='form-control'>
              <option value="" disabled selected>All avaliable  Areas</option>
            </select>

            <div class="alert d-none alert-danger" role="alert">
              you have to enter a value </div>
          </div>

          <div className="form-group mt-2">
            <label htmlFor="title" className='fw-bold'>Region Name</label>
            <input
              placeholder='Enter title'
              type="text"
              className="form-control"
              id="title"
              name="RegionName"
            />
            <div class="alert d-none alert-danger" role="alert">
              you have to enter a value </div>

          </div>
          <div className="form-group mt-2" dir='rtl' >
            <label htmlFor="title" className='fw-bold'>إسم المنطقة</label>
            <input
              placeholder='أدخل العنوان'
              type="text"
              className="form-control"
              id="arRegionName"
              name="arRegionName"
            />
            <div class="alert d-none alert-danger" role="alert">
              لقد أدخلت قيمة خاطئة </div>

          </div>
          <div className='d-flex justify-content-center flex-column my-3 flex-wrap'>
            <button type="submit"  className='btn btn-success bg-primColor fw-bolder fs-5 submitButton'>Add Region</button>
         
            <div className="alert AlertMessage d-none text-center mt-2 alert-danger  m-auto" role="alert">
               </div>
               
            <div className="alert SuccessMessage d-none text-center mt-2 alert-success  m-auto" role="alert">
               </div>
          </div>
          <Link to="/Region" className="btn btn-outline-success w-100 ">
                return to Region
              </Link>
        </form>
      </div >
    </div >


  );
}

export default AddRegion;
/*Helper Function */

// this function to clear the options of selector when we change the value of selector
const clearAreaSelector = () => {
  const selector = document.querySelector('#selectorAreas');
  while (selector.firstChild) {
    selector.removeChild(selector.firstChild);
  }
}

function creatDefaultELementInSelector(text) {
  let option = document.createElement('option');
  option.value = "";
  option.selected = true;
  option.disabled = true;
  option.innerHTML = text;
  return option;
}
// this function to append Child to Areas Selector
const appendChildToAreasSelector = (response) => {
  let areas = response;
  let selector = document.querySelector('#selectorAreas');
  let option = creatDefaultELementInSelector("All avaliable  Areas");
  selector.append(option);
  selector.style.display = 'block';
  areas.forEach((area) => {
    let option = document.createElement('option');
    option.value = area.id;
    option.innerHTML = area.name;
    selector.appendChild(option);
  })
}