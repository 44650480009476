import React, { useState, useEffect } from "react";
import areaStyle from "./Area.module.css";
import { Link, useNavigate } from "react-router-dom";
import ApiCalling from "../../Js Files/apiCalling";
import swal from "sweetalert";

function Area() {
  const navigate = useNavigate();
  const [response, setResponse] = useState({
    regionId: "",
    name: "",
    pageIndex: 0,
    areaid: "",
    govId: "",
    data: [],
  });
  const [arabicFlag, setArabicFlag] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllData(1, "", false);
  }, []);

  const getAllData = async (
    pageIndex = response.pageIndex + 1,
    govId = response.govId,
    searchFlag = false
  ) => {
    try {
      const apiResponse = await ApiCalling.getAllAreas(
        `?regionId=${response.regionId}&name=${response.name}&pageIndex=${pageIndex}&areaid=${response.areaid}&govId=${govId}`,
        localStorage.getItem("token")
      );
      if (apiResponse.data) {
        if (apiResponse.data?.length === 0) {
          setResponse((prevResponse) => ({
            ...prevResponse,
            data: searchFlag
              ? [...apiResponse.data]
              : [...response.data, ...apiResponse.data],
            pageIndex: apiResponse.pageIndex - 1,
          }));
        } else {
          setResponse((prevResponse) => ({
            ...prevResponse,
            data: searchFlag
              ? [...apiResponse.data]
              : [...response.data, ...apiResponse.data],
            pageIndex: pageIndex,
          }));
        }
      } else {
        if (apiResponse.code === 401) {
          navigate("/login");
        }
      }

      setLoading(false);
    } catch (error) {}
  };

  const loadMore = () => {
    document.querySelector(
      ".ArrowIconLoadMore"
    ).innerHTML = `<i class="fa-solid fa-spinner-third fa-spin"></i>`;
    document.querySelector(".ArrowIconLoadMore").disabled = true;

    setTimeout(() => {
      document.querySelector(
        ".ArrowIconLoadMore"
      ).innerHTML = `<i class="fa-solid fa-arrow-down"></i>`;
      document.querySelector(".ArrowIconLoadMore").disabled = false;
    }, 1000);

    getAllData();
  };

  const handleSearchByGov = (val) => {
    setResponse((prevResponse) => ({
      ...prevResponse,
      pageIndex: 0,
      govId: val,
      data: [],
    }));
    getAllData(1, val, true);
  };

  const deleteArea = async (id, e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        e.target.parentElement.parentElement.remove();
        await ApiCalling.DeleteArea(id, localStorage.getItem("token"));
        swal("Poof! Your Area has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Area hasn't been deleted!");
      }
    });
  };

  return (
    <>
      <div
        className={
          "container-fluid  justify-content-center d-flex align-items-center h-100 position-relative " +
          areaStyle.py_izi_5
        }
      >
        <div className="row w-100">
          <div className="w-100 h-100 StudentPage">
            <div className="container w-90 bg-white  rounded-4  pt-5 overflow-auto position-relative ">
              <div className="row">
                <div className="col-12">
                  <select
                    onChange={(e) => {
                      handleSearchByGov(e.target.value);
                    }}
                    style={{
                      width: "80%",
                      fontSize: "20px",
                    }}
                    name="Gov"
                    className="form-select px-3 py-2 m-auto mb-3 rounded-5"
                    id="selectorGovs"
                  >
                    <option value="" selected>
                      All Governorates
                    </option>
                    {/* <option value="1">Alexandria</option>
            <option value="2">Assiut</option>
            <option value="3">Aswan</option>
            <option value="4">Beheira</option>
            <option value="5">Bani Suef</option> */}
                    <option value="6">Cairo</option>
                    {/* <option value="7">Daqahliya</option>
                        <option value="8">Damietta</option>
                        <option value="9">Fayyoum</option>
                        <option value="10">Gharbiya</option> */}
                    <option value="11">Giza</option>
                    {/* <option value="12">Helwan</option>
                      <option value="13">Ismailia</option>
                      <option value="14">Kafr El-Sheikh</option>
                      <option value="15">Luxor</option>
                      <option value="16">Marsa Matrouh</option>
                      <option value="17">Minya</option>
                      <option value="18">Monofiya</option>
                      <option value="19">New Valley</option>
                      <option value="20">North Sinai</option>
                      <option value="21">Port Said</option>
                      <option value="22">Qalioubiya</option>
                      <option value="23">Qena</option>
                      <option value="24">Red Sea</option>
                      <option value="25">Sharqiya</option>
                      <option value="26">Sohag</option>
                      <option value="27">South Sinai</option>
                      <option value="28">Suez</option>
                      <option value="29">Tanta</option> */}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className={areaStyle.tableContainer}>
                    <table className="px-3 m-auto w-100 table table-hover">
                      <thead className="sticky-md-top">
                        <tr className="table-light fw-bolder text-center">
                          <th>No.</th>
                          <th>Area</th>
                          <th>Governorate</th>
                          <th className="">Edit</th>
                          <th className="">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading && (
                          <>
                            <tr className="placeholder-glow">
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-danger"></span>{" "}
                              </td>
                            </tr>
                            <tr className="placeholder-glow">
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-danger"></span>{" "}
                              </td>
                            </tr>
                            <tr className="placeholder-glow">
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-warning"></span>{" "}
                              </td>
                              <td className="text-center">
                                {" "}
                                <span className="rounded placeholder py-3 col-8 bg-danger"></span>{" "}
                              </td>
                            </tr>
                          </>
                        )}
                        {response?.data.map((area, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">
                              {arabicFlag ? area.arName : area.name}
                            </td>
                            <td className="text-center">
                              {getTheGovernorate(area.governorate, arabicFlag)}
                            </td>
                            <td className="text-center">
                              <Link
                                to="./EditArea"
                                className="btn btn-warning"
                                state={{ area: area }}
                              >
                                Edit
                              </Link>
                            </td>
                            <td className="text-center">
                              <button
                                data={area.id}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  await deleteArea(area.id, e);
                                }}
                                className="btn btn-danger rounded px-3 py-1"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row w-100 my-3 d-flex justify-content-center ">
                <div className="col-3  rounded-circle bg-primaryColor d-flex justify-content-center align-items-center">
                  <button
                    onClick={loadMore}
                    className={`${areaStyle.SearchLink} ArrowIconLoadMore border border-0`}
                  >
                    <i className="fa-solid fa-arrow-down"></i>
                  </button>
                </div>
                <div className=" col-3 rounded-circle bg-primaryColor d-flex justify-content-center align-items-center">
                  <Link to="./AddArea" className={areaStyle.SearchLink}>
                    <i className="fa-solid fa-plus"></i>
                  </Link>
                </div>
              </div>

              <div className="form-check form-switch position-absolute top-0 end-0 m-3">
                <input
                  className="form-check-input px-3 py-2"
                  type="checkbox"
                  id="arabicCheck"
                  onChange={(e) => {
                    setArabicFlag(e.target.checked);
                  }}
                />
                <label className="form-check-label" for="arabicCheck">
                  Arabic
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function getTheGovernorate(id, arabicFlag) {
  switch (String(id)) {
    case "1":
      return arabicFlag ? "الإسكندرية" : "Alexandria";
    case "2":
      return arabicFlag ? "بورسعيد" : "Port Said";
    case "3":
      return arabicFlag ? "السويس" : "Suez";
    case "4":
      return arabicFlag ? "دمياط" : "Damietta";
    case "5":
      return arabicFlag ? "الدقهلية" : "Dakahlia";
    case "6":
      return arabicFlag ? "القاهرة" : "Cairo";
    case "7":
      return arabicFlag ? "الشرقية" : "Sharkia";
    case "8":
      return arabicFlag ? "القليوبية" : "Qalyubia";
    case "9":
      return arabicFlag ? "كفر الشيخ" : "Kafr El Sheikh";
    case "10":
      return arabicFlag ? "الغربية" : "Gharbia";
    case "11":
      return arabicFlag ? "الجيزة" : "Giza";
    case "12":
      return arabicFlag ? "المنوفية" : "Monufia";
    case "13":
      return arabicFlag ? "البحيرة" : "Beheira";
    case "14":
      return arabicFlag ? "الإسماعيلية" : "Ismailia";
    case "15":
      return arabicFlag ? "بني سويف" : "Beni Suef";
    case "16":
      return arabicFlag ? "الفيوم" : "Fayoum";
    case "17":
      return arabicFlag ? "المنيا" : "Minya";
    case "18":
      return arabicFlag ? "أسيوط" : "Asyut";
    case "19":
      return arabicFlag ? "سوهاج" : "Sohag";
    case "20":
      return arabicFlag ? "قنا" : "Qena";
    case "21":
      return arabicFlag ? "أسوان" : "Aswan";
    case "22":
      return arabicFlag ? "الأقصر" : "Luxor";
    case "23":
      return arabicFlag ? "البحر الأحمر" : "Red Sea";
    case "24":
      return arabicFlag ? "الوادي الجديد" : "New Valley";
    case "25":
      return arabicFlag ? "مرسى مطروح" : "Matrouh";
    case "26":
      return arabicFlag ? "شمال سيناء" : "North Sinai";
    case "27":
      return arabicFlag ? "جنوب سيناء" : "South Sinai";
    default:
      return arabicFlag ? "المحافظة غير معروفة" : "Unknown Governorate";
  }
}

export default Area;
